<!--- NOTE: This is a Header specifically for the Error Page -->

<template>
  <header class="header__wrapper">
    <div class="header__section__logo">
      <!-- 
        [TODO]: 
        
        Please convert the "/settings" route as a variable (i.e. "HOME_URL") to 
        easily change this URL in the future. The "HOME_URL" variable should be
        stored in a global config file.
        - by @johnnypreavacom
        
      -->
      <router-link to="/" class="header__logo">
        <Logo />
      </router-link>
    </div>

    <div class="w-full flex flex-row-reverse px-8">
      <div class="text-sm text-gray-800">Preava Prevent Version: {{version}}</div>
    </div>

    <!-- 
      
      [IMPORTANT]:

      Since Preava Admin should only be opened on Desktop view, 
      all mobile menu components are removed. If we decide to 
      have mobile capabilities in the future, we need to add 
      these components here.
      - by @johnnypreavacom

    -->

  </header>
</template>
<script>
import UserMenu from "./subcomponents/UserMenu.vue"
import Logo from "@/assets/logos/PreavaLogo";
import axios from "axios";

export default {
  components: {
    UserMenu,
    Logo,
  },
  data() {
    return {
      showMenu: true,
      version: null
    }
  },
  created() {
    
    // If production, fetch the version.json. If development, skip
    if (process.env.NODE_ENV === "production") {
      axios
        .get("/version.json")
        .then((response) => this.version = response.data.tag )
        .catch((err) =>
          console.log("An error occurred while fetching version =>", err)
        );
    }else{
      this.version = "development"
    }
    
  },
};
</script>
<style scoped>
  @import './header.css';
</style>