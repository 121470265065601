<template>
  <ErrorDefault v-if="this.getErrorInfo.type === 'default'" :error="this.getErrorInfo" />
  <ErrorSupport v-else-if="this.getErrorInfo.type === 'support'" :error="this.getErrorInfo" />
  <ErrorSales v-else-if="this.getErrorInfo.type === 'sales' " :error="this.getErrorInfo" />
  <Error404 v-else />
  
</template>

<script>
import { useRoute } from "vue-router";

const NAME = "Error Page";

import Error404 from "./Error404.vue"
import ErrorSupport from "./ErrorSupport.vue"
import ErrorSales from "./ErrorSales.vue"
import ErrorDefault from "./ErrorDefault.vue"

export default {
  name: NAME,
  site_title: NAME,
  components: {
    Error404,
    ErrorSupport,
    ErrorSales,
    ErrorDefault
  },
  computed: {
    getErrorInfo() {
      var error = {
        code: (useRoute().query.code) ? useRoute().query.code : "N/A",
        details: (useRoute().query.details) ? useRoute().query.details : "N/A",
        message: (useRoute().query.message) ? useRoute().query.message : "N/A",
        status: (useRoute().query.status) ? useRoute().query.status : "N/A",
        type: (useRoute().query.type) ? useRoute().query.type : null,
        requestId: (useRoute().query.requestId) ? useRoute().query.requestId : "N/A",
        correlationId: (useRoute().query.correlationId) ? useRoute().query.correlationId : "N/A"
      }
      return error 
    }
  },
  created() {
  },
};
</script>
<style scoped>
</style>

